.upload-docx {
  .ant-upload-list {
    display: none;
  }
}

.ant-message .anticon {
  top: -3px;
}

.ant-upload-list-text .ant-upload-span > *, .ant-upload-list-picture .ant-upload-span > * {
  display: flex;
  align-items: center;
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  display: flex;
  align-items: center;
}

.ant-table-tbody > tr > td, .ant-table-thead > tr > th, .ant-table tfoot > tr > td, .ant-table tfoot > tr > th {
  word-break: break-all;
}

@media only screen and (max-width: 600px) {
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th, .ant-table tfoot > tr > td, .ant-table tfoot > tr > th {
    word-break: unset;
  }
}