body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  height: 100%;

  #root {
    min-height: 100vh;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.font-color-text-primary {
  color: #2574DB;
}

.btn-submit-form {
  background: #2D9CDB;
  border: 0;
  color: white;
  padding: 12px 18px
}

.bg-color-primary {
  background-color: #2574DB;

  &:hover {
    background-color: #1064d1;
  }
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;

  &:hover {
    background-color: #cccccc;
  }
}
